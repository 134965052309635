import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import LoadingView from '@/components/static/loading-view';
import {formatDateUniversal} from '@/util/date-utils';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import useApiVirtualCareGetPatient from '@/services/api-hooks/useApiVirtualCareGetPatient';
import {colorPalette} from '@/themes/darkmode';
import VirtualCarePatientCrawlingAvatar from '@/components/business/virtual-care/patient/virtual-care-patient-crawling-avatar';
import {ErrorMessage} from '@/components/form/error-message';

const VirtualCarePatientDetails = () => {
    const { t } = useTranslation();
    const { patientDetails, loading, error } = useApiVirtualCareGetPatient();

    return (
        <StyledWrapper className="virtual-care-block-patient" id="virtual-care-patient-information">
            {loading && <LoadingView />}
            {error && <ErrorMessage errorMessage={error.message} />}
            {patientDetails &&
                <>
                    <div>
                        <VirtualCarePatientCrawlingAvatar skinType={patientDetails?.skinType}/>
                    </div>
                    <div>
                        <span className="patient-name">
                            {patientDetails?.firstName}&nbsp;{patientDetails?.lastName}
                        </span>
                        <span className="patient-birthDate">
                            {t(['Born on', 'virtualCare.patient.bornOn'])}&nbsp;
                            {formatDateUniversal(formatApiDateToJSDate(patientDetails?.birthDate))}
                        </span>
                        <span className="patient-sex">
                            {t('patient.sexLabel.' + [patientDetails?.sex])}
                        </span>
                    </div>
                </>
            }
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      display:flex;
      align-items: center;
      div:first-child {
          margin-right: 20px;
      }
      
      div span {
          display:block;
          &.patient-name {
              font-size: 18px;
              font-weight: 600;
          }
          &.patient-sex {
              color: ${colorPalette.clearColor};
          }
      }
  }
`;

export default VirtualCarePatientDetails;
