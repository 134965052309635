import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {useTranslation} from 'react-i18next';
import patientCaregiver_female from '@/public/images/caregiver-avatar-female.svg';
import patientCaregiver_male from '@/public/images/caregiver-avatar-male.svg';
import VirtualCareGender from '@/types/virtual-care-gender';

type VirtualCarePatientCaregiverAvatarProps = {
    gender: VirtualCareGender | undefined
};

const VirtualCarePatientCaregiverAvatar = ({ gender }: VirtualCarePatientCaregiverAvatarProps) => {
    const { t } = useTranslation();

    const patientCaregiverGender = () => {
        switch(gender) {
        case VirtualCareGender.FEMALE:
            return patientCaregiver_female;
        case VirtualCareGender.MALE:
            return patientCaregiver_male;
        case VirtualCareGender.OTHER:
            return patientCaregiver_male;
        default:
            return patientCaregiver_male;
        }
    };

    if(gender) {
        return (
            <img className="caregiver-img" src={patientCaregiverGender()} alt={t(['Caregiver avatar', 'virtualCare.patient.caregiverAvatarAlt']).toString()} width={50}/>
        );
    } else {
        return <ComponentErrorMessage component="VirtualCarePatientCaregiverAvatar" />;
    }
};

export default VirtualCarePatientCaregiverAvatar;
