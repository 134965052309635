import { useState, useEffect } from 'react';
import RequestError from '@/types/request-error';
import VirtualCareApiDate from '@/types/virtual-care-api-date';
import {BackendApiService} from '@/services/backend-api-service';

const useApiVirtualCareGetFirstRecordingDate = () => {
    const [firstRecordingDate, setFirstRecordingDate] = useState<VirtualCareApiDate | null>(null);
    const [loading, setLoading] = useState<boolean | null>(true);
    const [error, setError] = useState<RequestError | null>(null);

    useEffect(() => {
        setError(null);
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'virtualCare',
            modelName: 'getFirstRecordingDate',
        })
            .then((response: { [key:string]:unknown }) => {
                const responseParsed = new VirtualCareApiDate(
                    response.year as number,
                    response.month as number,
                    response.day as number
                );
                setFirstRecordingDate(responseParsed);
                setLoading(false);
            })
            .catch((err: RequestError) => {
                setError(err);
                setLoading(false);
            });
    }, []);

    return { firstRecordingDate, loading, error };
};

export default useApiVirtualCareGetFirstRecordingDate;
