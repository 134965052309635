import { useState, useEffect } from 'react';
import RequestError from '@/types/request-error';
import VirtualCareSignalQuery from '@/types/virtual-care-signal-query';
import {BackendApiService} from '@/services/backend-api-service';

const useApiVirtualCareGetAverageForPeriod = ({from, to, signal}: VirtualCareSignalQuery) => {
    const [averageForPeriod, setAverageForPeriod] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean | null>(true);
    const [error, setError] = useState<RequestError | null>(null);

    useEffect(() => {
        setError(null);
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'virtualCare',
            modelName: 'getAverageForPeriod',
            data: {
                from: from,
                to: to,
                signal: signal
            }
        })
            .then((response: { [key:string]:unknown }) => {
                setAverageForPeriod(response.average as number);
                setLoading(false);
            })
            .catch((err: RequestError) => {
                setError(err);
                setLoading(false);
            });
    }, []);

    return { averageForPeriod, loading, error };
};

export default useApiVirtualCareGetAverageForPeriod;
