import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {Modal} from '@/components/layout/modal';
import {BackendApiService} from '@/services/backend-api-service.js';
import {Button} from '@/components/buttons/button.jsx';
import sliderIcon from '@/public/icons/slider-icon.svg';
import LoadingView from '@/components/static/loading-view';
import VirtualCareSignalsEventConfiguration from '@/types/virtual-care-signals-event-configuration';
import VirtualCareAnalyticsEventConfigurationForm from '@/components/business/virtual-care/analytics/virtual-care-analytics-event-configuration-form';

interface VirtualCareAnalyticsEventConfigurationProps {
    eventConfiguration: VirtualCareSignalsEventConfiguration,
    onSaved: (eventConfiguration: VirtualCareSignalsEventConfiguration) => void
}

const VirtualCareAnalyticsEventConfiguration = ({eventConfiguration, onSaved}: VirtualCareAnalyticsEventConfigurationProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [savedEventConfiguration, setSavedEventConfiguration] = useState(eventConfiguration);

    const handleEventConfigurationSaved = (selectedEventConfiguration: VirtualCareSignalsEventConfiguration) => {
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'virtualCare',
            modelName: 'updateEventConfiguration',
            data: {
                configuration: selectedEventConfiguration
            }
        })
            .then((response) => {
                setLoading(false);
                setModalOpen(false);
                setSavedEventConfiguration(selectedEventConfiguration);
                onSaved(selectedEventConfiguration);
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    };

    const handleClickOpenModalButton = () => {
        setModalOpen(true);
    };

    const resetEventConfiguration = () => {
        setSavedEventConfiguration(eventConfiguration);
        setModalOpen(false);
    };

    return (
        <StyledWrapper className="virtual-care-block-analytics" id={'virtual-care-analytics-event-configuration'}>
            <Button
                className="button-event-configuration"
                onClick={handleClickOpenModalButton}
                loading={loading}
            >
                {t(['Event configuration', 'eventConfiguration.title'])}
            </Button>
            {modalOpen &&
                <Modal title={t(['Event configuration', 'eventConfiguration.title'])} onClosed={resetEventConfiguration}>
                    {!savedEventConfiguration || loading ?
                        <LoadingView/>
                        :
                        <VirtualCareAnalyticsEventConfigurationForm
                            readonly={false}
                            eventConfiguration={savedEventConfiguration}
                            onSaved={(selectedEventConfiguration) => handleEventConfigurationSaved(selectedEventConfiguration)}
                        />
                    }
                </Modal>
            }
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
    && {
        .button-event-configuration {
            padding-left:45px;
            background-image: url(${sliderIcon});
            background-repeat: no-repeat;
            background-position: 15px center;
            border-radius: 20px;
        }
        .signal-event-configuration {
            margin-top:15px;
            margin-bottom: 30px;
            > span {
                display: inline-block;
                width: 130px;
                vertical-align: middle;
                text-align: left;
            }
            width:800px;
        }
    }
`;

export default VirtualCareAnalyticsEventConfiguration;
