import { useState, useEffect } from 'react';
import RequestError from '@/types/request-error';
import VirtualCareSignalsEventConfiguration from '@/types/virtual-care-signals-event-configuration';
import {BackendApiService} from '@/services/backend-api-service';
import VirtualCareSignalEventConfiguration from '@/types/virtual-care-signal-event-configuration';

const useApiVirtualCareGetEventConfiguration = () => {
    const [eventConfiguration, setEventConfiguration] = useState<VirtualCareSignalsEventConfiguration | null>(null);
    const [loading, setLoading] = useState<boolean | null>(true);
    const [error, setError] = useState<RequestError | null>(null);

    useEffect(() => {
        setError(null);
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'virtualCare',
            modelName: 'getEventConfiguration'
        })
            .then((response: { [key:string]:unknown }) => {
                const responseParsed = new VirtualCareSignalsEventConfiguration(
                    response.hr as VirtualCareSignalEventConfiguration,
                    response.spo2 as VirtualCareSignalEventConfiguration,
                    response.rr as VirtualCareSignalEventConfiguration
                );
                setEventConfiguration(responseParsed);
                setLoading(false);
            })
            .catch((err: RequestError) => {
                setError(err);
                setLoading(false);
            });
    }, []);

    return { eventConfiguration, loading, error };
};

export default useApiVirtualCareGetEventConfiguration;
