import React from 'react';
import PropTypes from 'prop-types';
import {colorPalette} from '@/themes/darkmode.js';

class ChartAxisTickHour extends React.PureComponent {
    static propTypes = {
        x: PropTypes.number,
        y: PropTypes.number,
        payload: PropTypes.object,
        textAnchor: PropTypes.string,
    };

    render() {
        const {
            x, y, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={14} textAnchor={this.props.textAnchor || 'end'} fill={colorPalette.frontColor} >
                    {payload.value}
                </text>
            </g>
        );
    }
}

export { ChartAxisTickHour };

