import styled from 'styled-components';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {colorPalette} from '@/themes/darkmode.js';
import {VirtualCareNavigationEnum} from '@/enum/virtual-care-navigation-enum';

const VirtualCareNavigation = () => {
    const { t } = useTranslation();
    const { hash } = useLocation();

    return (
        <StyledWrapper>
            <nav className="virtual-care-navigation">
                {Object.entries(VirtualCareNavigationEnum).map((navigationItem, i) => (
                    <Link
                        key={navigationItem[0]}
                        to={`#section-${navigationItem[1]}`}
                        className={((navigationItem[1] === VirtualCareNavigationEnum.PATIENT && hash === '') || (hash === `#section-${navigationItem[1]}`)) ? 'active' : ''}
                    >
                        <span>{i+1}</span>
                        {t(['Patient', `virtualCare.navigation.${navigationItem[1]}`])}
                    </Link>
                ))}
            </nav>
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
    nav a {
        position: relative;
        height: 60px;
        display:flex;
        align-items: center;
        width: 100%;
        padding: 10px 20px;
        color: ${colorPalette.frontColor};
        border-top: 1px solid ${colorPalette.mainBackground};
        &:last-child {
            border-bottom: 1px solid ${colorPalette.mainBackground};
        }
        span {
            display:block;
            margin-right:10px;
            padding:5px;
            color:${colorPalette.frontColor};
            border-radius:50px;
            width:30px;
            height:30px;
            text-align:center;
            background-color:${colorPalette.activeColor};
        }
        &:hover, &:focus {
            background-color: ${colorPalette.thirdBackground};
            text-decoration: none;
        }
        &.active {
            color:${colorPalette.activeColor};
            span {
              background-color: ${colorPalette.activeColor};
            }
        }
      }
  }
`;

export default VirtualCareNavigation;
