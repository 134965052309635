import styled from 'styled-components';
import LoadingView from '@/components/static/loading-view';
import {ErrorMessage} from '@/components/form/error-message';
import useApiVirtualCareGetCaregiver from '@/services/api-hooks/useApiVirtualCareGetCaregiver';
import {useTranslation} from 'react-i18next';
import VirtualCarePatientCaregiverAvatar from '@/components/business/virtual-care/patient/virtual-care-patient-caregiver-avatar';

const VirtualCarePatientCaregiverDetails = () => {
    const { t } = useTranslation();
    const { caregiverDetails, loading, error } = useApiVirtualCareGetCaregiver();

    return (
        <StyledWrapper className="virtual-care-block-patient" id="virtual-care-patient-caregiver-information">
            {loading && <LoadingView />}
            {error && <ErrorMessage errorMessage={error.message} />}
            {caregiverDetails &&
                <>
                    <div>
                        <VirtualCarePatientCaregiverAvatar gender={caregiverDetails.gender} />
                    </div>
                    <div>
                        <span className="caregiver-name">
                            {caregiverDetails?.firstName}&nbsp;{caregiverDetails?.lastName}&nbsp;({t(`global.gender.${caregiverDetails?.gender}`)})
                        </span>
                        <span className="caregiver-language">
                            {t(['Preferred language', 'virtualCare.patient.preferredLanguage'])}&nbsp;
                            {t(`global.language.${caregiverDetails?.language}`)}
                        </span>
                    </div>
                </>
            }
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      display:flex;
      div:first-child {
          margin-right: 20px;
      }
      div span {
          display:block;
          &.caregiver-name {
              font-size: 18px;
              font-weight: 600;
          }
      }
  }
`;

export default VirtualCarePatientCaregiverDetails;
