import styled from 'styled-components';
import {subHours} from 'date-fns';
import useApiVirtualCareGetAverageForPeriod from '@/services/api-hooks/useApiVirtualCareGetAverageForPeriod';
import {NewDateUtil} from '@/util/new-date-util';
import {
    getVirtualCareSignalTypeIdentifier,
    getVirtualCareSignalTypeUnit,
    VirtualCareSignalType
} from '@/types/virtual-care-signal-type';
import LoadingView from '@/components/static/loading-view';
import {ErrorMessage} from '@/components/form/error-message';
import VirtualCareSignalEventConfiguration from '@/types/virtual-care-signal-event-configuration';
import {colorPalette} from '@/themes/darkmode';
import ColoredContainer from '@/components/layout/colored-container';
import {useTranslation} from 'react-i18next';
import VirtualCareRealTimeAverageBar
    from '@/components/business/virtual-care/real-time/virtual-care-real-time-average-bar';

interface VirtualCareRealTimeAverageProps {
    signalType: VirtualCareSignalType,
    signalEventConfiguration: VirtualCareSignalEventConfiguration
}

const VirtualCareRealTimeAverage = ({signalType, signalEventConfiguration} : VirtualCareRealTimeAverageProps) => {
    const today = NewDateUtil();
    const { t } = useTranslation();
    const { averageForPeriod, loading, error } = useApiVirtualCareGetAverageForPeriod({
        from: subHours(today, 72),
        to: today,
        signal: signalType
    });

    return (
        <StyledWrapper className="virtual-care-block-realTime" id={`virtual-care-realTime-average-${getVirtualCareSignalTypeIdentifier(signalType)}`}>
            <ColoredContainer
                color={colorPalette.signalType[getVirtualCareSignalTypeIdentifier(signalType)]}
                title={`${t(`global.${getVirtualCareSignalTypeIdentifier(signalType)}`)} - ${t(['average', 'virtualCare.realTime.averageTitle'])}`}
            >
                {loading && <LoadingView />}
                {error && <ErrorMessage errorMessage={error.message} />}
                {averageForPeriod && signalEventConfiguration &&
                    <div className="average-bar-wrapper">
                        <span>{getVirtualCareSignalTypeUnit(signalType)}</span>
                        <VirtualCareRealTimeAverageBar
                            signalType={signalType}
                            average={parseInt(averageForPeriod.toFixed(0))}
                            eventConfiguration={signalEventConfiguration}
                        />
                    </div>
                }
            </ColoredContainer>
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      .average-bar-wrapper {
          display:flex;
          flex-wrap: nowrap;
          align-items: end;
          margin-bottom: 20px;
          > span {
              width: 50px;
              text-align: end;
              font-size: 16px;
              margin-bottom: 4px;
          }
          > div {
              width: calc(100% - 20px);
          }
      }
  }
`;

export default VirtualCareRealTimeAverage;
