import {useTranslation} from 'react-i18next';
import {colorPalette} from '@/themes/darkmode.js';
import React from 'react';
import styled from 'styled-components';
import VirtualCareEvent from '@/types/virtual-care-event';
import {formatDateTimeUniversal} from '@/util/date-utils';
import {getVirtualCareSignalTypeIdentifier} from '@/types/virtual-care-signal-type';
import {formatApiFullDateToJSDate} from '@/services/api-requests/requests-utils';

interface VirtualCareAnalyticsEventsListProps {
    data: VirtualCareEvent,
    onShowSignalSample: (data: VirtualCareEvent) => void
}

const VirtualCareAnalyticsEventsListTableRow = ({data, onShowSignalSample}: VirtualCareAnalyticsEventsListProps) => {
    const { t } = useTranslation();

    const getEventValue = (value: number) => {
        if(value) {
            return <span><strong>{value}</strong> {t(['sec', 'global.seconds'])}</span>;
        } else {
            return '-';
        }
    };

    const handleClickShowSignalSampleButton = (e: React.MouseEvent<HTMLButtonElement>, data: VirtualCareEvent) => {
        e.preventDefault();
        onShowSignalSample(data);
    };

    return (
        <StyledWrapper>
            {data &&
                <>
                    <td className="linkCell">
                        <button className="show-signal-sample-button" onClick={(e) => handleClickShowSignalSampleButton(e, data)}>Signal sample</button>
                    </td>
                    <td className="eventDateCell">
                        {formatDateTimeUniversal(formatApiFullDateToJSDate(data.timestamp))}
                    </td>
                    <td className={`eventsCell event-cell-${getVirtualCareSignalTypeIdentifier(data.signal)}`}>
                        {getEventValue(data.durationInSeconds)}
                    </td>
                </>
            }
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.tr`
    && {
        td {
            font-size: 12px;
        }
        .eventsCell {
            border-left: 4px solid ${colorPalette.activeColor};
            strong {
                font-size: 16px;
            }
            &.event-cell-pulseRate {
                border-left-color: ${colorPalette.signalType.pulseRate};
            }
            &.event-cell-spo2 {
                border-left-color: ${colorPalette.signalType.spo2};
            }
            &.event-cell-respiratoryRate {
                border-left-color: ${colorPalette.signalType.respiratoryRate};
            }
        }
    }
`;

export default VirtualCareAnalyticsEventsListTableRow;
