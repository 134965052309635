import styled from 'styled-components';
import ScrollToAnchor from '@/scroll-to-anchor';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import VirtualCareSidebar from '@/components/business/virtual-care/virtual-care-sidebar';
import VirtualCarePatient from '@/components/business/virtual-care/patient/virtual-care-patient';
import {colorPalette} from '@/themes/darkmode';
import arrowDownIcon from '@/public/icons/arrow-down-icon.svg';
import {VirtualCareNavigationEnum} from '@/enum/virtual-care-navigation-enum';
import VirtualCareRealTime from '@/components/business/virtual-care/real-time/virtual-care-real-time';
import VirtualCareAnalytics from '@/components/business/virtual-care/analytics/virtual-care-analytics';
import VirtualCareHcpConclusion from '@/components/business/virtual-care/conclusion/virtual-care-hcp-conclusion';
import ErrorBoundary from '@/components/errors/error-boundary';

const PatientVirtualCarePage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        validateToken();
    }, []);

    const validateToken = () => {
        // TODO : VALIDATE TOKEN EVERY 30 SECONDS
        return true;
    };

    return (
        <StyledWrapper>
            <ErrorBoundary>
                <ScrollToAnchor />
                <VirtualCareSidebar />

                <div className="virtual-care-wrapper">
                    {Object.entries(VirtualCareNavigationEnum).map((navigationItem) => (
                        <div key={`section-${navigationItem[0]}`} id={`section-${navigationItem[1]}`} className="virtual-care-section">
                            {navigationItem[1] === VirtualCareNavigationEnum.PATIENT &&
                                <>
                                    <VirtualCarePatient/>

                                    <nav>
                                        <Link className="virtual-care-section-link" to={`#section-${VirtualCareNavigationEnum.REALTIME}`}>
                                            {t(['Real time data', `virtualCare.navigation.${VirtualCareNavigationEnum.REALTIME}`])}
                                        </Link>
                                    </nav>
                                </>
                            }
                            {navigationItem[1] === VirtualCareNavigationEnum.REALTIME &&
                                <>
                                    <VirtualCareRealTime />

                                    <nav>
                                        <Link className="virtual-care-section-link" to={`#section-${VirtualCareNavigationEnum.ANALYTICS}`}>
                                            {t(['Last 72h', `virtualCare.navigation.${VirtualCareNavigationEnum.ANALYTICS}`])}
                                        </Link>
                                    </nav>
                                </>
                            }
                            {navigationItem[1] === VirtualCareNavigationEnum.ANALYTICS &&
                                <>
                                    <VirtualCareAnalytics />

                                    {/*<nav>
                                        <Link className="virtual-care-section-link" to={`#section-${VirtualCareNavigationEnum.ALERTS}`}>
                                            {t(['Alerts', `virtualCare.navigation.${VirtualCareNavigationEnum.ALERTS}`])}
                                        </Link>
                                    </nav>*/}

                                    <nav>
                                        <Link className="virtual-care-section-link" to={`#section-${VirtualCareNavigationEnum.CONCLUSION}`}>
                                            {t(['Conclusion', `virtualCare.navigation.${VirtualCareNavigationEnum.CONCLUSION}`])}
                                        </Link>
                                    </nav>
                                </>
                            }
                            {/*{navigationItem[1] === VirtualCareNavigationEnum.ALERTS &&
                                <>
                                    <VirtualCareAlerts />

                                    <nav>
                                        <Link className="virtual-care-section-link" to={`#section-${VirtualCareNavigationEnum.CONCLUSION}`}>
                                            {t(['Conclusion', `virtualCare.navigation.${VirtualCareNavigationEnum.CONCLUSION}`])}
                                        </Link>
                                    </nav>
                                </>
                            }*/}
                            {navigationItem[1] === VirtualCareNavigationEnum.CONCLUSION &&
                                <VirtualCareHcpConclusion />
                            }
                        </div>
                    ))}
                </div>
            </ErrorBoundary>
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      .virtual-care-wrapper {
          width:calc(100% - 250px);
          margin-left:250px;
          .virtual-care-section {
              min-height: 100vh;
              padding: 75px 50px;
              position: relative;
              header {
                  text-align: center;
                  h2 {
                      font-size: 40px;
                      margin-bottom: 15px;
                      font-family: 'Oooh Baby', cursive;
                      color: ${colorPalette.activeColor};
                  }
                  h3 {
                      font-size:20px;
                      margin-bottom:5px;
                      font-weight: 600;
                  }
                  h4 {
                      font-size: 14px;
                  }
              }
              nav {
                  margin-top:40px;
                  text-align:center;
                  position: absolute;
                  bottom: 50px;
                  width: 95%;
                  .virtual-care-section-link {
                      background: ${colorPalette.activeColor} url(${arrowDownIcon}) no-repeat 17px center;
                      color: ${colorPalette.frontColor};
                      padding: 15px 20px 15px 45px;
                      border-radius: 30px;
                      background-size: 13px;
                      text-align: left;
                      &:hover, &:focus {
                          text-decoration: none;
                          background-color: ${colorPalette.activeColorDarker};
                      }
                  }
              }              
          }
      }
  }
`;

export default PatientVirtualCarePage;
