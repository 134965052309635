import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import EventThresholdConfigurationSlider
    from '@/components/business/analytics/common/event-threshold-configuration-slider';
import EventSensibilityConfigurationSlider
    from '@/components/business/analytics/common/event-sensibility-configuration-slider';
import {Button} from '@/components/buttons/button';
import sliderIcon from '@/public/icons/slider-icon.svg';
import {InfoMessage} from '@/components/form/info-message';
import VirtualCareSignalsEventConfiguration from '@/types/virtual-care-signals-event-configuration';
import VirtualCareSignalEventConfiguration from '@/types/virtual-care-signal-event-configuration';
import VirtualCareThreshold from '@/types/virtual-care-threshold';
import VirtualCareSensibility from '@/types/virtual-care-sensibility';
import {VirtualCareSignalType} from '@/types/virtual-care-signal-type';

interface VirtualCareAnalyticsEventConfigurationFormProps {
    readonly: boolean,
    eventConfiguration: VirtualCareSignalsEventConfiguration,
    loading?: boolean,
    onSaved: (selectedEventConfiguration: VirtualCareSignalsEventConfiguration) => void
}

const VirtualCareAnalyticsEventConfigurationForm = ({readonly, eventConfiguration, loading, onSaved}: VirtualCareAnalyticsEventConfigurationFormProps) => {
    const { t } = useTranslation();
    const [selectedEventConfiguration, setSelectedEventConfiguration] = useState<VirtualCareSignalsEventConfiguration>(eventConfiguration);

    const saveEventConfiguration = () => {
        onSaved(selectedEventConfiguration);
    };

    const resetEventConfiguration = () => {
        setSelectedEventConfiguration(eventConfiguration);
    };

    const handleChangePulseRateThresholdConfiguration = (range: Array<number>) => {
        const updatedSelectedHrConfiguration = new VirtualCareSignalEventConfiguration(
            new VirtualCareThreshold(range[1]),
            new VirtualCareThreshold(range[0]),
            new VirtualCareSensibility(selectedEventConfiguration.hr.sensibility.seconds)
        );

        const updatedSelectedConfiguration = new VirtualCareSignalsEventConfiguration(
            updatedSelectedHrConfiguration,
            selectedEventConfiguration.spo2,
            selectedEventConfiguration.rr
        );

        setSelectedEventConfiguration(updatedSelectedConfiguration);
    };

    const handleChangePulseRateSensibilityConfiguration = (value: number) => {
        const updatedSelectedHrConfiguration = new VirtualCareSignalEventConfiguration(
            new VirtualCareThreshold(selectedEventConfiguration.hr.high.value),
            new VirtualCareThreshold(selectedEventConfiguration.hr.low.value),
            new VirtualCareSensibility(value)
        );

        const updatedSelectedConfiguration = new VirtualCareSignalsEventConfiguration(
            updatedSelectedHrConfiguration,
            selectedEventConfiguration.spo2,
            selectedEventConfiguration.rr
        );

        setSelectedEventConfiguration(updatedSelectedConfiguration);
    };

    const handleChangeSpo2ThresholdConfiguration = (value: number) => {
        const updatedSelectedSpO2Configuration = new VirtualCareSignalEventConfiguration(
            new VirtualCareThreshold(100),
            new VirtualCareThreshold(value),
            new VirtualCareSensibility(selectedEventConfiguration.spo2.sensibility.seconds)
        );

        const updatedSelectedConfiguration = new VirtualCareSignalsEventConfiguration(
            selectedEventConfiguration.hr,
            updatedSelectedSpO2Configuration,
            selectedEventConfiguration.rr
        );

        setSelectedEventConfiguration(updatedSelectedConfiguration);
    };

    return (
        <StyledWrapper>
            <div>
                {selectedEventConfiguration &&
                    <>
                        <div className="signal-event-configuration">
                            <span>{t(['Pulse Rate (bpm)', 'thresholds.pulseRate'])}</span>
                            <EventThresholdConfigurationSlider
                                lowValue={selectedEventConfiguration.hr.low.value}
                                highValue={selectedEventConfiguration.hr.high.value}
                                sliderMin={50}
                                sliderMax={200}
                                sliderStep={5}
                                signalType={VirtualCareSignalType.SIGNAL_PR}
                                onChanged={handleChangePulseRateThresholdConfiguration}
                            />

                            <span>{t(['Sensitivity (seconds)', 'eventConfiguration.sensibility'])}</span>
                            <EventSensibilityConfigurationSlider
                                value={selectedEventConfiguration.hr.sensibility.seconds}
                                sliderMin={0}
                                sliderMax={30}
                                sliderStep={1}
                                signalType={VirtualCareSignalType.SIGNAL_PR}
                                onChanged={handleChangePulseRateSensibilityConfiguration}
                            />
                        </div>
                        <div className="signal-event-configuration">
                            <span>{t(['SpO2 (%)', 'thresholds.spO2'])}</span>
                            <EventThresholdConfigurationSlider
                                lowValue={selectedEventConfiguration.spo2.low.value}
                                highValue={selectedEventConfiguration.spo2.high.value}
                                sliderMin={70}
                                sliderMax={100}
                                sliderStep={1}
                                signalType={VirtualCareSignalType.SIGNAL_SPO2}
                                onChanged={handleChangeSpo2ThresholdConfiguration}
                            />
                        </div>
                    </>
                }

                <div className="step-form-actions">
                    <Button className="button-create" loading={loading} disabled={readonly} onClick={saveEventConfiguration}>{t(['Save', 'patient.save'])}</Button>
                    <Button className="button-cancel" onClick={resetEventConfiguration}>{t(['Cancel', 'eventConfiguration.cancel'])}</Button>
                </div>

                {readonly &&
                    <InfoMessage infoMessage={t(['Event configuration changes cannot be applied to Demo Patient.', 'eventConfiguration.demoInfoMessage'])}/>
                }
            </div>
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      .button-event-configuration {
          position:absolute;
          right:10px;
          top:5px;
          padding-left:45px;
          background-image: url(${sliderIcon});
          background-repeat: no-repeat;
          background-position: 15px center;
          border-radius: 20px;
      }
      .signal-event-configuration {
          margin-top:15px;
          margin-bottom: 30px;
          > span {
              display: inline-block;
              width: 130px;
              vertical-align: middle;
              text-align: left;
          }
          width:800px;
      }
  }
`;

export default VirtualCareAnalyticsEventConfigurationForm;
