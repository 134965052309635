import PatientVirtualCarePage from '@/pages/virtual-care/patient-virtual-care.tsx';
import RootVirtualCare from '@/root-virtual-care';
import Err404 from '@/pages/err404';
import PatientVirtualCareErrorPage from '@/pages/virtual-care/patient-virtual-care-error';

const routes = [
    {
        path: '/',
        element: <RootVirtualCare />,
        children: [
            {
                path: '*',
                element: <Err404 />
            },
            {
                path: 'token/:token',
                element: <PatientVirtualCarePage />
            },
            {
                path: 'virtual-care-error',
                element: <PatientVirtualCareErrorPage />
            }
        ]
    }
];

export default routes;
