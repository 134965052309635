import { useState, useEffect } from 'react';
import RequestError from '@/types/request-error';
import VirtualCareCaregiverLastCall from '@/types/virtual-care-caregiver-last-call';
import VirtualCareConclusion from '@/types/virtual-care-conclusion';
import VirtualCareApiFullDate from '@/types/virtual-care-api-full-date';
import {BackendApiService} from '@/services/backend-api-service';

const useApiVirtualCareGetCaregiverLastCall = () => {
    const [caregiverLastCall, setCaregiverLastCall] = useState<VirtualCareCaregiverLastCall | null>(null);
    const [loading, setLoading] = useState<boolean | null>(true);
    const [error, setError] = useState<RequestError | null>(null);

    useEffect(() => {
        setError(null);
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'virtualCare',
            modelName: 'getCaregiverLastCall',
        })
            .then((response: { [key:string]:unknown }) => {
                const responseParsed = new VirtualCareCaregiverLastCall(
                    response.date as VirtualCareApiFullDate,
                    response.conclusion as VirtualCareConclusion,
                    response.alertsHaveBeenModified as boolean
                );
                setCaregiverLastCall(responseParsed);
                setLoading(false);
            })
            .catch((err: RequestError) => {
                setError(err);
                setLoading(false);
            });
    }, []);

    return { caregiverLastCall, loading, error };
};

export default useApiVirtualCareGetCaregiverLastCall;
