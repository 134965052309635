import styled from 'styled-components';
import LoadingView from '@/components/static/loading-view';
import {ErrorMessage} from '@/components/form/error-message';
import {useTranslation} from 'react-i18next';
import {NewDateUtil} from '@/util/new-date-util';
import {subHours} from 'date-fns';
import {colorPalette} from '@/themes/darkmode';
import {
    getVirtualCareSignalTypeIdentifier,
    getVirtualCareSignalTypeUnit,
    VirtualCareSignalType
} from '@/types/virtual-care-signal-type';
import VirtualCareSignalEventConfiguration from '@/types/virtual-care-signal-event-configuration';
import useApiVirtualCareGetAggregatedSignal from '@/services/api-hooks/useApiVirtualCareGetAggregatedSignal';
import {Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis,} from 'recharts';
import {roundTo} from '@/util/number-util';
import {NameType, ValueType} from 'recharts/types/component/DefaultTooltipContent';
import {
    getDomainForSignalType
} from '@/components/business/virtual-care/analytics/virtual-care-analytics-signals-domains';
import VirtualCareEvent from '@/types/virtual-care-event';
import {ChartAxisTickHour} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-hour';
import ColoredContainer from '@/components/layout/colored-container';

interface VirtualCareAnalyticsAggregatedSignalProps {
    signalType: VirtualCareSignalType,
    signalEventConfiguration?: VirtualCareSignalEventConfiguration,
    event?: VirtualCareEvent
}

const VirtualCareAnalyticsAggregatedSignal = ({signalType, signalEventConfiguration, event} : VirtualCareAnalyticsAggregatedSignalProps) => {
    const today = NewDateUtil();
    const { t } = useTranslation();
    const { aggregatedSignal, loading: aggregatedSignalLoading, error: aggregatedSignalError } = useApiVirtualCareGetAggregatedSignal({
        from: subHours(today, 72),
        to: today,
        signal: signalType
    });

    console.log(aggregatedSignal);
    console.log(event);

    const renderTooltip = ({payload}: TooltipProps<ValueType, NameType>) => {
        if (payload) {
            const value = payload[0]?.payload?.value.value;
            const date = payload[0]?.payload?.at;

            return (
                <div className="chart-tooltip">
                    {date && <span>{date.date}&nbsp;{date.time}</span>}
                    {value ?
                        <span>{roundTo(value, 2)}{getVirtualCareSignalTypeUnit(signalType)}</span>
                        :
                        <span>{t(['No recording data', 'healthReport.aggregatedSignal.noRecordingData'])}</span>
                    }
                </div>
            );
        }
        return null;
    };

    /*const renderMoon = ({viewBox}: object) => {
        console.log(viewBox);
        const size = 20;
        const difference = size / 2;

        const transformSun = `translate(${x - difference - 20} ${y + 5})`;
        const transformMoon = `translate(${x + difference} ${y + 5})`;

        return (
            <>
                <g
                    transform={transformSun}
                    style={{ cursor: 'pointer' }}
                >
                    <SunIcon />
                </g>
                <g
                    transform={transformMoon}
                    style={{ cursor: 'pointer' }}
                >
                    <MoonIcon />
                </g>
            </>
        );
    };

    const renderSun = ({viewBox}: unknown):ReactNode => {
        const size = 20;
        console.log(viewBox);
        const difference = size / 2;

        const transformMoon = `translate(${x - difference - 20} ${y + 5})`;
        const transformSun = `translate(${x + difference} ${y + 5})`;

        return (
            <>
                <g
                    transform={transformMoon}
                    style={{ cursor: 'pointer' }}
                >
                    <MoonIcon />
                </g>
                <g
                    transform={transformSun}
                    style={{ cursor: 'pointer' }}
                >
                    <SunIcon />
                </g>
            </>
        );
    };*/

    return (
        <StyledWrapper className="virtual-care-block-analytics" id={`virtual-care-analytics-aggregatedSignal-${getVirtualCareSignalTypeIdentifier(signalType)}`}> 
            <ColoredContainer
                color={colorPalette.signalType[getVirtualCareSignalTypeIdentifier(signalType)]}
                title={`${t(`global.${getVirtualCareSignalTypeIdentifier(signalType)}`)} - ${t(['aggregated signal - last 72h', 'virtualCare.analytics.aggregatedSignalTitle'])}`}
            >
                {aggregatedSignalLoading && <LoadingView />}
                {aggregatedSignalError && <ErrorMessage errorMessage={aggregatedSignalError.message} />}
                {!aggregatedSignalLoading && aggregatedSignal &&
                    <ResponsiveContainer className="virtual-care-analytics-aggregatedSignal-chart" width="100%" height={120}>
                        <LineChart height={300} data={aggregatedSignal.blocksList} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                            <XAxis
                                dataKey="at.time"
                                interval="preserveStart"
                                tick={<ChartAxisTickHour textAnchor="middle" />}
                                padding={{ right: 20 }}
                            />
                            <YAxis
                                dataKey="value.value"
                                tickCount={4}
                                unit={getVirtualCareSignalTypeUnit(signalType)}
                                domain={getDomainForSignalType(signalType)}
                                padding={{ top: 10, bottom: 10 }}
                            />
                            <Tooltip
                                content={renderTooltip}
                                isAnimationActive={false}
                            />
                            <Line
                                type="monotone"
                                dot={false}
                                dataKey="value.value"
                                stroke={colorPalette.clearColor}
                                strokeWidth={1.5}
                            />
                            <Line
                                type="monotone"
                                dot={false}
                                dataKey="reliableValue.value"
                                stroke={colorPalette.signalType[getVirtualCareSignalTypeIdentifier(signalType)]}
                                strokeWidth={2}
                            />
                            {/*{aggregatedSignal.blocksList.map((block, i) => {
                                if (block.at.time === '18:00') {
                                    console.log(i);
                                    return (
                                        <ReferenceLine
                                            key={i}
                                            x={i}
                                            stroke={(signalType === VirtualCareSignalType.SIGNAL_ACTIGRAPHY) ? colorPalette.frontColor: colorPalette.clearColor}
                                            label={(signalType === VirtualCareSignalType.SIGNAL_ACTIGRAPHY) ? <Label position="top" content={renderMoon} /> : ''}
                                            strokeDasharray="4"
                                        />
                                    );
                                }
                                if (block.at.time === '07:00') {
                                    console.log(i);
                                    return (
                                        <ReferenceLine
                                            key={i}
                                            x={i}
                                            stroke={(signalType === VirtualCareSignalType.SIGNAL_ACTIGRAPHY) ? colorPalette.frontColor: colorPalette.clearColor}
                                            label={(signalType === VirtualCareSignalType.SIGNAL_ACTIGRAPHY) ? <Label position="top" content={renderSun} /> : ''}
                                            strokeDasharray="4"
                                        />
                                    );
                                }
                            })}*/}
                            {/*{event &&
                            <ReferenceArea
                                key={event.timestampNumber}
                                x1={event.timestampNumber}
                                x2={event.timestampNumber + event.durationInSeconds}
                                fill={colorPalette.signalType[getVirtualCareSignalTypeIdentifier(signalType)]}
                                strokeOpacity={0.0}
                                fillOpacity={0.25}
                            />
                            }*/}
                            {signalEventConfiguration && signalType !== VirtualCareSignalType.SIGNAL_SPO2 &&
                                <ReferenceLine
                                    y={signalEventConfiguration.high.value}
                                    label={{
                                        position: (signalEventConfiguration.high.value >= (getDomainForSignalType(signalType)[1] - (5))) ? 'bottom' : 'top',
                                        value: `${signalEventConfiguration.high.value}${getVirtualCareSignalTypeUnit(signalType)}`,
                                        fill: colorPalette.clearColor
                                    }}
                                    stroke={colorPalette.clearColor}
                                    strokeDasharray="4"
                                />
                            }
                            {signalEventConfiguration &&
                                <ReferenceLine
                                    y={signalEventConfiguration.low.value}
                                    label={{
                                        position: (signalEventConfiguration.low.value <= (getDomainForSignalType(signalType)[0] + (5))) ? 'top' : 'bottom',
                                        value: `${signalEventConfiguration.low.value}${getVirtualCareSignalTypeUnit(signalType)}`,
                                        fill: colorPalette.clearColor
                                    }}
                                    stroke={colorPalette.clearColor}
                                    strokeDasharray="4"
                                />
                            }
                        </LineChart>
                    </ResponsiveContainer>
                }
            </ColoredContainer>
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
    && {
        margin-bottom: 10px;
        
        .virtual-care-analytics-aggregatedSignal-chart {
            margin-top:20px;
        }
        
        .chart-tooltip {
            span {
                display:block;
            }
        }
    }
`;

export default VirtualCareAnalyticsAggregatedSignal;
