import styled from 'styled-components';
import LoadingView from '@/components/static/loading-view';
import {ErrorMessage} from '@/components/form/error-message';
import useApiVirtualCareGetCaregiverLastCall from '@/services/api-hooks/useApiVirtualCareGetCaregiverLastCall';
import {useTranslation} from 'react-i18next';
import {colorPalette} from '@/themes/darkmode';
import {NewDateUtil} from '@/util/new-date-util';
import {formatApiFullDateToJSDate} from '@/services/api-requests/requests-utils';
import VirtualCareApiFullDate from '@/types/virtual-care-api-full-date';
import {InfoMessage} from '@/components/form/info-message';

const VirtualCarePatientCaregiverLastCall = () => {
    const { t } = useTranslation();
    const { caregiverLastCall, loading, error } = useApiVirtualCareGetCaregiverLastCall();

    const getDurationInDays = (caregiverLastCallDate: VirtualCareApiFullDate) => {
        const today = NewDateUtil().getTime();
        const firstDate = formatApiFullDateToJSDate(caregiverLastCallDate).getTime();
        const diffTime = Math.abs(today - firstDate);

        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    return (
        <StyledWrapper className="virtual-care-block-patient" id="virtual-care-patient-caregiver-last-call">
            {loading && <LoadingView />}
            {error && <ErrorMessage errorMessage={error.message} />}
            {caregiverLastCall &&
                <>
                    <span>{t(['Caregiver last call', 'virtualCare.patient.caregiverLastCall'])}</span>

                    {caregiverLastCall.date ?
                        <div className="caregiver-last-call">
                            <span className="caregiver-last-call-days">
                                {t([`{{${getDurationInDays(caregiverLastCall.date)}}} days ago`, 'virtualCare.patient.lastCallDate'], {days: getDurationInDays(caregiverLastCall.date)})}
                            </span>
                            <p>{caregiverLastCall.conclusion.conclusion}</p>
                            {caregiverLastCall.alertsHaveBeenModified &&
                            <p className="alert-modified">
                                {t(['Caregiver alerts have been modified', 'virtualCare.patient.alertsHaveBeenModified'])}
                            </p>
                            }
                        </div>
                        :
                        <InfoMessage infoMessage={t(['No previous call from the caregiver.', 'virtualCare.patient.caregiverLastCallEmpty'])} />
                    }
                </>
            }
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      > span:first-child {
          font-size: 18px;
          font-weight: 600;
          display:block;
      }
      div.caregiver-last-call {
          background: ${colorPalette.thirdBackground};
          border-radius: 10px;
          border-left: 10px solid ${colorPalette.activeColor};
          padding: 10px 15px;
          margin-top: 20px;
      }
      p {
          margin-bottom: 5px;
      }
      .caregiver-last-call-days {
          display:block;
          margin-bottom: 10px;
          font-size:12px;
          color: ${colorPalette.clearColor};
      }
      .alert-modified {
          padding: 10px 15px;
          background: ${colorPalette.clearColor};
          border-radius: 5px;
          margin-top: 15px;
      }
  }
`;

export default VirtualCarePatientCaregiverLastCall;
