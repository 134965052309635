import styled from 'styled-components';
//import {useTranslation} from 'react-i18next';
import LoadingView from '@/components/static/loading-view';
import {ErrorMessage} from '@/components/form/error-message';
import PatientHealthConditionLabel from '@/components/business/analytics/patient/patient-health-condition/patient-health-condition-label';
import useApiVirtualCareGetHealthCondition from '@/services/api-hooks/useApiVirtualCareGetHealthCondition';
import {useTranslation} from 'react-i18next';

const VirtualCarePatientHealthCondition = () => {
    const { t } = useTranslation();
    const { healthCondition, loading, error } = useApiVirtualCareGetHealthCondition();

    return (
        <StyledWrapper className="virtual-care-block-patient" id="virtual-care-patient-health-condition">
            {loading && <LoadingView />}
            {error && <ErrorMessage errorMessage={error.message} />}
            {healthCondition &&
                <>
                    <span>{t(['Health condition', 'virtualCare.patient.healthCondition'])}</span>
                    <p>{healthCondition.healthConditionDetail}</p>
                    {healthCondition.healthConditionTypes.healthConditionTypesList.map((healthConditionType: string) => (
                        <PatientHealthConditionLabel key={healthConditionType} healthConditionLabel={healthConditionType} />
                    ))}
                </>
            }
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      span:first-child {
          font-size: 18px;
          font-weight: 600;
      }
  }
`;

export default VirtualCarePatientHealthCondition;
