import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import useApiVirtualCareGetEventConfiguration from '@/services/api-hooks/useApiVirtualCareGetEventConfiguration';
import LoadingView from '@/components/static/loading-view';
import {ErrorMessage} from '@/components/form/error-message';
import {VirtualCareSignalType} from '@/types/virtual-care-signal-type';
import VirtualCareAnalyticsAggregatedSignal from '@/components/business/virtual-care/analytics/virtual-care-analytics-aggregated-signal';
import VirtualCareAnalyticsEventsList
    from '@/components/business/virtual-care/analytics/virtual-care-analytics-events-list';
import VirtualCareAnalyticsEventConfiguration
    from '@/components/business/virtual-care/analytics/virtual-care-analytics-event-configuration';
import VirtualCareSignalsEventConfiguration from '@/types/virtual-care-signals-event-configuration';
import VirtualCareEvent from '@/types/virtual-care-event';

const VirtualCareAnalytics = () => {
    const { t } = useTranslation();
    const { eventConfiguration, loading: eventConfigurationLoading, error: eventConfigurationError } = useApiVirtualCareGetEventConfiguration();

    const handleEventConfigurationUpdated = (selectedEventConfiguration: VirtualCareSignalsEventConfiguration) => {
        console.log(selectedEventConfiguration);  
    };

    const onShowEventOnChart = (event: VirtualCareEvent) => {
        console.log(event);
    };
    
    return (
        <StyledWrapper>
            <header>
                <h2>{t(['What happened?', 'virtualCare.analytics.title'])}</h2>
                <h3>{t(['You can access data for the last 72H', 'virtualCare.analytics.subtitle'])}</h3>
                <h4>{t(['Your event configuration will not impact caregiver alerts', 'virtualCare.analytics.description'])}</h4>
            </header>

            <div className="virtual-care-analytics-content">
                {eventConfigurationLoading && <LoadingView />}
                {eventConfigurationError && <ErrorMessage errorMessage={eventConfigurationError.message} />}
                {eventConfiguration &&
                <>
                    <div className="virtual-care-analytics-event-configuration-wrapper">
                        <VirtualCareAnalyticsEventConfiguration
                            eventConfiguration={eventConfiguration}
                            onSaved={(selectedEventConfiguration) => handleEventConfigurationUpdated(selectedEventConfiguration)}
                        />
                    </div>

                    <div className="virtual-care-analytics-content-wrapper">
                        <div className="virtual-care-analytics-aggregated-signal-wrapper">
                            <VirtualCareAnalyticsAggregatedSignal
                                signalType={VirtualCareSignalType.SIGNAL_PR}
                                signalEventConfiguration={eventConfiguration.hr}
                            />
                            <VirtualCareAnalyticsAggregatedSignal
                                signalType={VirtualCareSignalType.SIGNAL_SPO2}
                                signalEventConfiguration={eventConfiguration.spo2}
                            />
                            <VirtualCareAnalyticsAggregatedSignal
                                signalType={VirtualCareSignalType.SIGNAL_ACTIGRAPHY}
                            />
                        </div>

                        <div className="virtual-care-analytics-events-list-wrapper">
                            <VirtualCareAnalyticsEventsList
                                eventConfiguration={eventConfiguration}
                                onShowSignalSample={(event) => onShowEventOnChart(event)}
                            />
                        </div>
                    </div>
                </>
                }
            </div>
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      .virtual-care-analytics-content {
          width: 95%;
          margin: 0 auto;
          
          .virtual-care-analytics-event-configuration-wrapper {
              width:100%;
              text-align: center;
              margin:30px 0;
          }
          
          .virtual-care-analytics-content-wrapper {
              display:flex;
              .virtual-care-analytics-aggregated-signal-wrapper {
                  width:calc(100% - 360px);
                  margin-right:10px;
              }
              .virtual-care-analytics-events-list-wrapper {
                  width:350px;
                  margin-left:10px;
              }
          }
      }
  }
`;

export default VirtualCareAnalytics;
