import {useTranslation} from 'react-i18next';
import LoadingView from '@/components/static/loading-view.tsx';
import styled from 'styled-components';
import useApiVirtualCareGetEventsList from '@/services/api-hooks/useApiVirtualCareGetEventsList';
import {subHours, subMinutes} from 'date-fns';
import {NewDateUtil} from '@/util/new-date-util';
import {ErrorMessage} from '@/components/form/error-message';
import TableSortable from '@/components/table/table-sortable';
import VirtualCareAnalyticsEventsListTableRow
    from '@/components/business/virtual-care/analytics/virtual-care-analytics-events-list-table-row';
import VirtualCareEvent from '@/types/virtual-care-event';
import {colorPalette} from '@/themes/darkmode';
import {
    getVirtualCareSignalTypeIdentifier,
    VirtualCareSignalType
} from '@/types/virtual-care-signal-type';
import VirtualCareAnalyticsSignalSample
    from '@/components/business/virtual-care/analytics/virtual-care-analytics-signal-sample';
import {formatApiFullDateToJSDate} from '@/services/api-requests/requests-utils';
import ColoredContainer from '@/components/layout/colored-container';
import {useState} from 'react';
import {Modal} from '@/components/layout/modal';
import VirtualCareSignalsEventConfiguration from '@/types/virtual-care-signals-event-configuration';

interface VirtualCareAnalyticsEventsListProps {
    eventConfiguration: VirtualCareSignalsEventConfiguration,
    onShowSignalSample: (data: VirtualCareEvent) => void
}

const VirtualCareAnalyticsEventsList = ({ eventConfiguration, onShowSignalSample } : VirtualCareAnalyticsEventsListProps) => {
    const today = NewDateUtil();
    const { t } = useTranslation();
    const [ showSignalSample, setShowSignalSample ] = useState<boolean>(false);
    const [ signalSampleData, setSignalSampleData ] = useState<VirtualCareEvent>();
    const { events, loading, error } = useApiVirtualCareGetEventsList({
        from: subHours(today, 72),
        to: today
    });

    const tableSortableHeaderColumn = [
        {
            label: '',
            accessor: 'id',
            sortable: false
        },
        {
            label: t(['Event time', 'virtualCare.analytics.eventsList.eventTime']),
            accessor: 'timestampNumber',
            sortable: true
        },
        {
            label: t(['Duration', 'virtualCare.analytics.eventsList.eventDuration']),
            accessor: 'durationInSeconds',
            className: 'signalTableHeader',
            sortable: true
        },
    ];

    const getSignalEventConfiguration = (signalType: VirtualCareSignalType) => {
        switch(signalType) {
        case VirtualCareSignalType.SIGNAL_PR:
            return eventConfiguration.hr;
        case VirtualCareSignalType.SIGNAL_SPO2:
            return eventConfiguration.spo2;
        case VirtualCareSignalType.SIGNAL_RR:
            return eventConfiguration.rr;
        default:
            return eventConfiguration.hr;
        }
    };

    const onShowSignalSampleExample = (rowData: VirtualCareEvent) => {
        setShowSignalSample(true);
        setSignalSampleData(rowData);
        onShowSignalSample(rowData);
    };

    const onCloseSignalSample = () => {
        setShowSignalSample(false);
        //setSignalSampleData(null);
    };

    return (
        <StyledWrapper className="virtual-care-block-analytics" id="virtual-care-analytics-events-list">
            {loading && <LoadingView />}
            {error && <ErrorMessage errorMessage={error.message} />}
            {showSignalSample && signalSampleData &&
            <Modal title={''} onClosed={onCloseSignalSample}>
                <ColoredContainer color={colorPalette.signalType[getVirtualCareSignalTypeIdentifier(signalSampleData.signal)]}
                    title={t(['Event - signal sample', 'virtualCare.analytics.eventsList.signalSampleTitle'])}>
                    <VirtualCareAnalyticsSignalSample
                        signalType={signalSampleData.signal}
                        timestamp={subMinutes(formatApiFullDateToJSDate(signalSampleData.timestamp), 1)}
                        durationInSeconds={300}
                        eventConfiguration={getSignalEventConfiguration(signalSampleData.signal)}
                    />
                </ColoredContainer>
            </Modal>
            }
            {events &&
                <TableSortable
                    data={events.eventList}
                    columns={tableSortableHeaderColumn}
                    defaultSorting={{sortByOrder: 'asc', accessor: 'time'}}
                    onShowSignalSample={(rowData) => onShowSignalSampleExample(rowData)}
                    TableBodyRowComponent={VirtualCareAnalyticsEventsListTableRow}
                />
            }
            {(events?.eventList.length === 0) &&
                <div className="empty-message">{t(['No events for the last 72h', 'virtualCare.analytics.eventsList.empty'])}</div>
            }
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      border-radius: 10px;
      height: 100vh;
      overflow-y: scroll;
      ::-webkit-scrollbar {
          display:none;
      }
      table {
          background: ${colorPalette.secondBackground};
      }
      h3 {
          font-size:12px;
          margin-bottom:25px;
          color:${props => props.theme.colorPalette.clearColor};
      }
      th {
          font-size:12px;
      }
      .linkCell {
          padding: 10px;
      }
      .eventDateCell {
          width: 120px;
      }
      .eventsCell {
          width: 120px;
      }
  }
`;

export default VirtualCareAnalyticsEventsList;
