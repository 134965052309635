import VirtualCareApiFullDate from '@/types/virtual-care-api-full-date';
import {VirtualCareSignalType} from '@/types/virtual-care-signal-type';
import {VirtualCareAlertType} from '@/types/virtual-care-alert-type';

class VirtualCareEvent {
    constructor(
        readonly timestamp: VirtualCareApiFullDate,
        readonly timestampNumber: number,
        readonly signal: VirtualCareSignalType,
        readonly alertType: VirtualCareAlertType,
        readonly durationInSeconds: number
    ) {}
}

export default VirtualCareEvent;
