import {useTranslation} from 'react-i18next';
import {colorPalette} from '@/themes/darkmode.js';
import {CartesianGrid, Line, LineChart, Tooltip, TooltipProps, XAxis, YAxis} from 'recharts';
import {roundTo} from '@/util/number-util';
import {formatDateTimeUniversal} from '@/util/date-utils';
import {
    getVirtualCareSignalTypeIdentifier,
    getVirtualCareSignalTypeUnit,
    VirtualCareSignalType
} from '@/types/virtual-care-signal-type';
import styled from 'styled-components';
import {
    getDomainForSignalType
} from '@/components/business/virtual-care/analytics/virtual-care-analytics-signals-domains';
import VirtualCareSignalData from '@/types/virtual-care-signal-data';
import {NameType, ValueType,} from 'recharts/types/component/DefaultTooltipContent';
import {
    ChartAxisTickHourMinutesFromTimestamp
} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-hour-minutes-from-timestamp';

interface VirtualCareAnalyticsActigraphySignalSampleProps {
    actigraphyData: VirtualCareSignalData
}

const VirtualCareAnalyticsActigraphySignalSample = ({actigraphyData}: VirtualCareAnalyticsActigraphySignalSampleProps) => {
    const { t } = useTranslation();

    const renderTooltip = ({payload}: TooltipProps<ValueType, NameType>) => {
        if (payload) {
            const color = (payload[0]?.payload?.reliability && payload[0]?.payload?.reliableValue.value && payload[0]?.payload?.reliableValue.value !== 'noData') ?
                colorPalette.signalType[getVirtualCareSignalTypeIdentifier(VirtualCareSignalType.SIGNAL_ACTIGRAPHY)] :
                colorPalette.clearColor;

            return (
                <div className="chart-tooltip">
                    <strong style={{color: color}}>
                        <div>{t(['Movements', 'global.movements'])}:&nbsp;
                            {(payload[0]?.payload?.reliability && payload[0]?.payload?.reliableValue?.value) ?
                                roundTo(payload[0]?.payload?.reliableValue?.value, 2) + 'G'
                                : (payload[0]?.payload?.value === 'noData') ?
                                    t(['No data available', 'global.noDataAvailable']) :
                                    t(['Not Reliable', 'global.notReliable'])
                            }
                        </div>
                    </strong>
                    {formatDateTimeUniversal(new Date(payload[0]?.payload?.tsAt))}
                </div>
            );
        }
        return null;
    };

    return (
        <StyledWrapper className="virtual-care-block-analytics" id="virtual-care-analytics-actigraphy-signal-sample">
            {actigraphyData &&
                <LineChart data={actigraphyData.blocksList} width={450} height={100}>
                    <CartesianGrid strokeDasharray="1 3" />

                    <XAxis dataKey="tsAt" tick={<ChartAxisTickHourMinutesFromTimestamp textAnchor="right" />} interval={60} />
                    <YAxis
                        domain={getDomainForSignalType(VirtualCareSignalType.SIGNAL_ACTIGRAPHY)}
                        unit={getVirtualCareSignalTypeUnit(VirtualCareSignalType.SIGNAL_ACTIGRAPHY)}
                        type="number"
                        tickCount={4}
                        tickFormatter={(tick) => (tick === Math.floor(tick)) ? tick : tick.toFixed(2)} />

                    <Line
                        isAnimationActive={false}
                        type="linear"
                        dot={false}
                        activeDot={false}
                        dataKey="value"
                        stroke={colorPalette.clearColor}
                        strokeWidth={1.2}
                        connectNulls={false}
                    />
                    <Line
                        isAnimationActive={false}
                        type="linear"
                        dot={false}
                        activeDot={false}
                        dataKey="reliableValue.value"
                        stroke={colorPalette.signalType[getVirtualCareSignalTypeIdentifier(VirtualCareSignalType.SIGNAL_ACTIGRAPHY)]}
                        strokeWidth={1.5}
                        opacity={1}
                        connectNulls={false}
                    />
                    <Tooltip
                        content={renderTooltip}
                        isAnimationActive={false}
                    />
                </LineChart>
            }
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      margin-top:5px;
  }
`;

export default VirtualCareAnalyticsActigraphySignalSample;
