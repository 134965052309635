import { useState, useEffect } from 'react';
import RequestError from '@/types/request-error';
import VirtualCareCaregiver from '@/types/virtual-care-caregiver';
import {
    parseApiGenderToVirtualCareGender,
    parseApiLanguageToVirtualCareLanguage
} from '@/services/api-requests/virtual-care-requests-utils';
import {Gender, Language} from 'gabi-api-js/vct/vct_common_pb';
import {BackendApiService} from '@/services/backend-api-service';

const useApiVirtualCareGetCaregiver = () => {
    const [caregiverDetails, setCaregiverDetails] = useState<VirtualCareCaregiver | null>(null);
    const [loading, setLoading] = useState<boolean | null>(true);
    const [error, setError] = useState<RequestError | null>(null);

    useEffect(() => {
        setError(null);
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'virtualCare',
            modelName: 'getCaregiver',
        })
            .then((response: { [key:string]:unknown }) => {
                const responseParsed = new VirtualCareCaregiver(
                    response.firstName as string,
                    response.lastName as string,
                    parseApiGenderToVirtualCareGender(response.gender as Gender),
                    parseApiLanguageToVirtualCareLanguage(response.language as Language),
                );
                setCaregiverDetails(responseParsed);
                setLoading(false);
            })
            .catch((err: RequestError) => {
                setError(err);
                setLoading(false);
            });
    }, []);

    return { caregiverDetails, loading, error };
};

export default useApiVirtualCareGetCaregiver;
