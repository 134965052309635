import VirtualCareThreshold from '@/types/virtual-care-threshold';
import VirtualCareSensibility from '@/types/virtual-care-sensibility';

class VirtualCareSignalEventConfiguration {
    constructor(
        readonly high: VirtualCareThreshold,
        readonly low: VirtualCareThreshold,
        readonly sensibility: VirtualCareSensibility
    ) {}
}

export default VirtualCareSignalEventConfiguration;
