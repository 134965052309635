import VirtualCareBiometricValue from '@/types/virtual-care-biometric-value';
import VirtualCareApiFullDate from '@/types/virtual-care-api-full-date';

class VirtualCareSignalDataBlock {
    constructor(
        readonly at: VirtualCareApiFullDate,
        readonly tsAt: number,
        readonly value: number,
        readonly reliability: boolean,
        readonly reliableValue: VirtualCareBiometricValue,
        readonly unixTs: number
    ) {}
}

export default VirtualCareSignalDataBlock;
