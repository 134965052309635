import {BackendApiService} from '@/services/backend-api-service';
import {MockTransportService} from '@/services/mock-transport-service';
import {FirestoreTransportService} from '@/services/firestore-transport-service';
import {Outlet, useParams} from 'react-router-dom';
import './style.js';
import OutdatedBrowserView from '@/components/static/outdated-browser-view';
import isBrowserSupported from '@/util/browser-support';
import {Auth0Service} from '@/services/auth0-service';

// Display Gabi Environment and define Transport Service
console.log(`Gabi environment: "${__GABI_ENV__}"`);

if(__GABI_ENV__ === 'testing')  {
    BackendApiService.setTransportService(new MockTransportService());
} else {
    BackendApiService.setTransportService(new FirestoreTransportService());
}

// Root component, including common components surrounding active route
export default function RootVirtualCare() {
    const { token } = useParams();

    if(document.location.href.indexOf('virtual-care-error') < 0) {
        if(token) {
            Auth0Service.setAccessTokenManually(token);
        } else {
            document.location['href'] = '/virtual-care-error';
        }
    }

    if (!isBrowserSupported()) {
        return <OutdatedBrowserView/>;
    } else {
        return (
            <Outlet/>
        );
    }
}
